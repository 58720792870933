import React from 'react';

const Card = ({ title, github, text, imageUrl, className }) => {
  return (
    <div className={`custom-card ${className}`}>
      <img src={imageUrl} alt={title} />
      <div className="card-body">
        <h5 className="card-title">
          <a href={github || '#'} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </h5>
        <p className="card-text">{text}</p>
      </div>
    </div>
  );
};

export default Card;
