// src/components/AboutMe.js
import React from "react";
import { Link } from "react-router-dom";
import "./css/AboutMe.css"; // Import the CSS file for styling

const AboutMe = () => {
  return (
    <div className="about-me">
      <div className="header">
        <Link to="/" className="home-button">Go to Home</Link>
      </div>
      <h1 className="h1About">About Me</h1>
      <div className="profile-container">
        <img
          src="https://cdn.discordapp.com/attachments/1057225201059893259/1334668131108519977/3.png?ex=679e06cf&is=679cb54f&hm=2c30f45a58104ed6c4592b755ba6c3c529780548bb421f4aaad35908b20988de&" // Replace with your actual image
          alt="Profile"
          className="profile-img"
        />
        <p>
          Hi there! I'm Felippe, a passionate game developer with a strong focus on creating
          interactive and immersive experiences. I've been working with various technologies,
          including React, Unity, and Python, and I love bringing my ideas to life through
          coding and design.
        </p>
        <p>
          Over the years, I've gained experience in both backend and frontend development,
          creating applications that engage users and provide seamless interactions. I’m
          particularly skilled in game mechanics, player movement, and AI systems. I'm always
          eager to explore new areas, learn more, and challenge myself with exciting projects.
        </p>

        <h3 className="ways-contact">Ways to contact me: 
          <a className="iconsAbout" href="https://github.com/Felippefn" target="__blank">
          <i class="bi bi-github"></i>
          </a>
          <a className="iconsAbout" href="https://www.linkedin.com/in/felippefn/" target="__blank">
          <i class="bi bi-linkedin"></i>
          </a>
          <a className="iconsAbout"  href="mailto:flppprojects@outlook.com" target="__blank">
          <i class="bi bi-envelope-fill"></i>
          </a>
          <a className="iconsAbout"  href="https://discordapp.com/users/330511498352918529" target="__blank">
          <i class="bi bi-discord"></i>
          </a>
          </h3>
      </div>
      <h2 className="h2About">Skills & Technologies</h2>
      <ul className="ulAbout">
        <li>Game Development (Unity, C#)</li>
        <li>Web Development (React, Node.js, Express)</li>
        <li>Database Management (MongoDB, Mongoose)</li>
        <li>Python (Django, APIs)</li>
        <li>Problem-Solving & Debugging</li>
      </ul>
      <h2>What I'm Passionate About</h2>
      <p>
        I'm deeply passionate about creating interactive systems that engage users, whether it's
        through gaming or innovative web applications. The future of technology excites me, and I
        look forward to pushing the boundaries of what's possible.
      </p>
    </div>
  );
};

export default AboutMe;
