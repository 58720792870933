// src/components/GamingPortfolio.js
import React from "react";

const TwitchPortifolio = () => {
  return (
    <div>
      <h1>My twitch portfolio</h1>
      <p>Here are my twitch development ...</p>
    </div>
  );
};

export default TwitchPortifolio;
