import React, { useState } from 'react';
import Card from './Card';
import projectsData from './assets/projects.json';  // Update this with the correct path

const TopProject = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredProjects = projectsData.filter(project =>
    project.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='top-project'>
      <input 
        type="text" 
        placeholder="Search projects..." 
        value={searchTerm} 
        onChange={(e) => setSearchTerm(e.target.value)} 
        className="search-bar"
      />
      <div className='card-container'>
        {filteredProjects.map((project, index) => (
          <Card 
            key={index} 
            title={project.title}
            github={project.github} 
            text={project.text} 
            imageUrl={project.imageUrl} 
            className="custom-card"
          />
        ))}
      </div>
    </div>
  );
};

export default TopProject;
