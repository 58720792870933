// client/src/components/Experience.js

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Experience() {
  const experiences = [
    {
      image: 'https://i3.ypcdn.com/blob/910ed70ed2fcae8d339a440fa61feb1fada62726',
      company: 'Best Buy',
      role: 'Computer Sales Advisor',
      duration: 'Nov 2023 - Present',
      description: ' Provided technical support, troubleshooting, and guidance to customers on various computer-related issues.'
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-uRbW9kTFZFVMGpQJjrDKE_HF0zMhfSMP_w&s',
      company: 'Freelance - Premium Wraps FL',
      role: 'Freelance Web Developer',
      duration: 'Dec 2022 - Feb 2023',
      description: "I led a team of two individuals in the development of a website for an automotive dealership in Florida. The client commissioned the project with the objective of enhancing the dealership's visibility and increasing the volume of vehicle orders."
    },
    {
      image: 'https://raichu-uploads.s3.amazonaws.com/logo_justa_G1yKwU.png',
      company: 'Justa Soluções Financeiras',
      role: 'Data Scientist Junior',
      duration: 'Oct 2021 - Aug 2022',
      description: 'I conducted data analysis for the logistics department of the company. Through data analysis, automation of several manual processes was also implemented using bots and web scraping tools in Python. As a result of process automation, the company was able to save over 30,000 BRL (approximately 6,000 USD) due to the loss of card machines and paper rolls. Additionally, the automation of manual processes resulted in a 50% reduction in work time.'
    }
  ];

  return (
    <Container>
      <h1 className='text-white title-work text-center'>Work Experience</h1>
      <div className="horizontal-line"></div>
      <Row>
        {experiences.map((exp, index) => (
          <Col key={index} md={12} className="mb-4">
            <div className="work-experience">
            {exp.image && <img src={exp.image} alt={`${exp.company} logo`} className="work-experience-image" />}
              <h5 className='text-white'>{exp.company}</h5>
              <p><strong className='text-white'>Role:</strong> {exp.role}</p>
              <p><strong className='text-white'>Duration:</strong> {exp.duration}</p>
              <p className='text-white'>- {exp.description}</p>
            </div>
            {index < experiences.length - 1 && <div className="horizontal-line"></div>}
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Experience;
