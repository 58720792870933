// client/src/components/Landing.js

import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import About from './About'; // Import the About component
import Experience from './Experience'; // Import the Experience component
import TopProjects from './TopProjects'; // Import the TopProjects component
import backSpaceImg from './assets/images/back_space.jpg'; // Adjust path if needed
import Footer from './Footer';



function Landing(props) {
  const [text] = useTypewriter({
    words: ['Back End Developer', 'Game Developer', 'Full Stack Developer'],
    loop: 0, // Infinite loop
    typeSpeed: 70,
    deleteSpeed: 50,
    delaySpeed: 2000,
  });

  return (
    <div className='landing-page-main'>
      <div className='landing-page' style={{ backgroundImage: `url(${backSpaceImg})` }}>
        <Container className='text-center my-5'>
          <h1>👋 Hi, I am Felippe</h1>
          <h1><span>{text}</span><Cursor /></h1>
          <div className="text-white social-home">
            <a id="a1" href="https://github.com/Felippefn" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-github"></i>
            </a>
            <a id="a2" href="https://linkedin.com/in/felippefn" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
          <div className="social-buttons">
            <a href='https://cdn.discordapp.com/attachments/415958635802263552/1334941796345708585/FelippeFN_Resume_Dev.pdf?ex=679e5cee&is=679d0b6e&hm=ab05cac1538b9b583b00a6ed8930d2bead3d4b82786790ea189fe13277e70743&' className="btn btn-primary" download> <i class="bi bi-download"></i> Resume</a>
            <a href="/#hub" className="btn btn-warning">Hub</a>
            <a href="/#about-me" className="btn btn-secondary">Contact</a>
          </div>
        </Container>
      </div>
      <Container className='content-container'>
        <Row>
          <Col md={5}>
            <img src="about-me/PROF_PIC.jpg" className='img-about' alt="About me" />
            <div className="horizontal-line"></div>
            <h3 className='text-white title-education'>Education: </h3>
            <p className='text-white title-education'>
              <img src="about-me/valencia.png" className='img-valencia' alt="Education" /> 
              AS. Computer Science <strong>.2023</strong>
            </p>
            <h5 className='text-white title-education'>Certifications: </h5>
            <p className='text-white text-center cert-items'>
              <a className='cert-items' href='https://www.credly.com/badges/72790fb6-16f2-41dd-a615-1533c5f28d0c/linked_in_profile'>
                <strong>Certified Entry-Level Python Programmer </strong>
              </a>
            </p>
            <p className='text-white text-center'>
              <a className='cert-items' href='https://verify.w3schools.com/1P1HOL91KP'>
                <strong>Certified JavaScript Developer W3Schools </strong>
              </a>
            </p>
          </Col>
          <Col md={1} className="separator">
            <div className="vertical-line"></div>
          </Col>
          <Col md={6}>
            <h1 className='title-about'>About Me</h1>
            <About /> {/* Include the About component here */}
            <div className="horizontal-line"></div>
          </Col>
        </Row>
      </Container>
      <Container className='content-container'>
        <Experience /> 
      </Container>
      <Container className='content-container'>
        <Row>
          <Col md={6}>
            <h1 className='title-projects'>Projects</h1>
            <div className="horizontal-line"></div>
          </Col>
          <Col md={12}>
            <TopProjects /> {/* Include the TopProjects component here */}
          </Col>
        </Row>
      </Container>
      <Footer></Footer>
    </div>
  );
}

export default Landing;