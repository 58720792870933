import React, { useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

function Footer() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');
  
    if (!name || !email || !message) {
      setError('Please fill in all fields.');
      setLoading(false);
      return;
    }
  
    try {
      const response = await fetch('http://localhost:3001/mail/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });
  
      if (response.ok) {
        setSuccess('Email sent successfully');
        setName('');
        setEmail('');
        setMessage('');
      } else {
        setError('Failed to send message. Please try again later.');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <footer className="page-footer font-small blue pt-4 bg-dark text-white">
      <div className="container-fluid text-center text-md-left">
        <div className="row">
          <div className="col-md-6 mt-md-0 mt-3">
            <h5 className="text-uppercase">Get in Touch</h5>
            {error && <p className="text-danger mt-2">{error}</p>}
            {success && <p className="text-success mt-2">{success}</p>}
          </div>

          <hr className="clearfix w-100 d-md-none pb-0" />

          <div className="col-md-8 mb-md-0 mb-3">
            <ul className="list-unstyled ul-icons">
              <li><a href="mailto:flppprojects@outlook.com" className="text-white"><i className="fa fa-envelope"></i></a></li>
              <li><a href="https://www.linkedin.com/in/felippefn/" className="text-white"><i className="fab fa-linkedin-in"></i></a></li>
              <li><a href="https://github.com/Felippefn" className="text-white"><i className="fab fa-github"></i></a></li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer-copyright text-center py-3">
        © 2024 Copyright: <a href="/" className="text-white">Felippefn</a>
      </div>
    </footer>
  );
}

export default Footer;
