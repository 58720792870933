import React from "react";
import { Link } from "react-router-dom";
import "./css/GamingPortfolio.css";  // Import CSS

const GamingPortfolio = () => {
  const games = [
    {
      title: "Cat's Life Game - In Progress",
      github: '',
      text: "Be a cat, survive, fight, hide.",
      imageUrl: "https://imagedelivery.net/WLUarKbmUXuuhDC7PG5_Qw/articles/c8426601a981185b735b73c92dd48dc2.jpeg/public",
    },
    {
      title: "Apocalypse - Coming Soon",
      github: '',
      text: "Survive the apocalypse with strategic gameplay.",
      imageUrl: "https://dzamqefpotdvf.cloudfront.net/p/images/21b1e7a8-a3d1-4085-b85e-22c1e88c73c7_Own_basement_in_apocalypse.PNG",
    },
    {
      title: "Space Adventure - To Be Announced",
      github: '',
      text: "Explore the universe and discover new worlds.",
      imageUrl: "https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/2178820/ss_cd250dd317c6ea4a3c55c8379e2e2b481b568151.1920x1080.jpg?t=1669757355",
    }
  ];

  return (
    <div className="gaming-portfolio">
      <Link to="/" className="home-button-gaming">Go to Home</Link>
      <h1 className="pixel-font p-5">Welcome to My Game Portfolio!</h1>
      <div className="introduction">
        <p className="p-5">Here you will find games that I created or In progress.<br></br>
        <i class="set right"></i><a className="about" href="/about-me">Who am I?</a></p>
      </div>
      <div className="gaming-card-container">
        {games.map((game, index) => (
          <a key={index} href={game.github || "#"} className="card">
            <img src={game.imageUrl} alt={game.title} className="card__img" />
            <span className="card__footer">
              <span>{game.title}</span>
              <span><i class="set right"></i> {game.text}</span>
            </span>
            <span className="card__action">
              <svg viewBox="0 0 448 512" title="play">
                <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z" />
              </svg>
            </span>
          </a>
        ))}
      </div>
      <div class="arrow">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default GamingPortfolio;
