// client/src/App.js

import { Container } from 'react-bootstrap';
import { Outlet, Route, Routes } from 'react-router-dom';

import Landing from './components/Landing';
import TopProjects from './components/TopProjects';
import Experience from './components/Experience';
import Contact from './components/Contact';
import About from './components/About'; // Import About component
import GamingPortfolio from './tabs/GamingPortfolio';
import TwitchPortfolio from './tabs/TwitchPortfolio';
import AboutMe from './tabs/AboutMe';
import HubWebsite from './tabs/HubWebsite';

import './App.css';


function Layout() {
  return (
    <>

      <Container className='m-0 p-0'>
        <Outlet />
      </Container>

    </>
  );
}

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Landing />} />
        <Route path='about' element={<About />} />
        <Route path='top-projects' element={<TopProjects />} />
        <Route path='experience' element={<Experience />} />
        <Route path='contact' element={<Contact />} />
        <Route path="/gaming-portfolio" element={<GamingPortfolio />} />
        <Route path='/about-me' element={<AboutMe />} />
        <Route path="/twitch" element={<TwitchPortfolio />} />
        <Route path="/hub" element={<HubWebsite />} />
      </Route>
    </Routes>
  );
}

export default App;
