import React from "react";
import { Link } from "react-router-dom";
import "./css/HubWebsite.css"; // Import the CSS file for styling

const HubWebsite = () => {
    return (
        <div>
            {/* Go Back Home Button */}
            <div className="top-nav">
                <Link to="/" className="go-home"><i class="bi bi-chevron-double-left"></i> Go Back Home</Link>
            </div>

            <div className="ag-format-container">
                <div className="ag-courses_box">
                    {[
                        { title: "Gaming Dev", link: "/#gaming-portfolio" },
                        { title: "Main Portfolio Website", link: "/" },
                        { title: "Twitch Streamer Hobbie", link: "https://www.twitch.tv/felippelado" },
                        { title: "About/Contact", link: "/#about-me" },
                        { title: "Github", link: "https://github.com/Felippefn" },
                        { title: "LinkedIn", link: "https://www.linkedin.com/in/felippefn/" },
                        { title: "Design & Art - Coming Soon", link: "/#design&art" },
                        { title: "E-commerce - Coming Soon", link: "https://www.example.com" },
                    ].map((course, index) => (
                        <div className="ag-courses_item" key={index}>
                            <a href={course.link} className="ag-courses-item_link"> {/* ✅ Fixed this line */}
                                <div className="ag-courses-item_bg"></div>
                                <div className="ag-courses-item_title">{course.title}</div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        <footer>Model used: <a href="https://codepen.io/wikyware-net/pen/dyKPRxQ" target="__blank">Codepen.io</a></footer>
        </div>
    );
};

export default HubWebsite;
